import revive_payload_client_4sVQNw7RlN from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/buildhome/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_LDumGYo2KH from "/opt/buildhome/repo/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_bidRKewKK5 from "/opt/buildhome/repo/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_244lXBzhnM from "/opt/buildhome/repo/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_i8AMfKeYnY from "/opt/buildhome/repo/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import i18n_yfWm7jX06p from "/opt/buildhome/repo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import view_transitions_client_2LpVRIemQY from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import autofocus_when_window_is_active_client_0hdLlrwm4U from "/opt/buildhome/repo/plugins/autofocus-when-window-is-active.client.ts";
import click_outside_directive_YjNOGbcjIP from "/opt/buildhome/repo/plugins/click-outside-directive.ts";
import persistedstate_o0qkr1uoQ4 from "/opt/buildhome/repo/plugins/persistedstate.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  slideovers_LDumGYo2KH,
  modals_bidRKewKK5,
  colors_244lXBzhnM,
  plugin_client_i8AMfKeYnY,
  i18n_yfWm7jX06p,
  view_transitions_client_2LpVRIemQY,
  chunk_reload_client_UciE0i6zes,
  autofocus_when_window_is_active_client_0hdLlrwm4U,
  click_outside_directive_YjNOGbcjIP,
  persistedstate_o0qkr1uoQ4
]